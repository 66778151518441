tr, td, th{
  border:none !important;
  background-color: #fff !important;
}
.table-forecast{
  border-collapse: inherit !important; 
}
.card-header{
  background-color: #f5f8fb !important;
}
.dark-accordion{
  background-color:#2e3650 !important;
}
.accordion > .card{
  border:1px solid #eaedf2;
}
table{
  border:1px solid #eaedf2 !important;
}
th{
  background-color: #f5f8fb !important;
}
tr{
  border-bottom:1px solid #eaedf2 !important;
}
button{
  outline: none !important;
  box-shadow: none !important;
}
.btn-default{
  border:1px solid #3d3d3d !important;
}

.dropleft > button{
  border:none !important; 
}

button[type=reset]:hover {
  color:#fa5252 !important;
  border:1px solid #fa5252 !important;
}
#dropdown-basic-button:hover{
  border:none !important;
}
button[type=submit]:hover:not(.btn-primary), .btn-default:hover{
  color:#0948b3 !important;
  border:1px solid #0948b3 !important;
} 

.body-modal-campaign{
  min-height:65vh;
  padding:20px;
}

.nav-tabs > a:not(.active){
  color:#0062cc !important;
}
.nav-tabs > a:not(.active):hover{
  color:#000 !important;
}
#statistics-tab{
  position:absolute; 
  right:0;
  border-color:#007bff;
  color:#007bff !important;
}

#statistics-tab:hover, #statistics-tab[aria-selected=true]{
  position:absolute; 
  right:0;
  border-color:#007bff;
  background-color:#007bff;
  color:#ffffff !important;
}

.fa-times{
  font-size: 13px !important;
}

.fa-paperclip{
  font-size:18px !important
}

html, body{
  width:100%;
  height:100vh;
  margin:0;
  padding:0;
}

.dash-containter{
  display:flex;
  width:100%;
  height:100vh;
  max-height:100vh;
}
.navbar{
  background-color:#262b40 !important;
  z-index:100000 !important;
}

.dash-containter > div:nth-child(1){
  width:240px;
  background-color:#262b40;
  padding:20px;
  height:100vh;
  position:fixed;
  z-index:1000;
}
.dash-containter > div:nth-child(2){
  margin-left:240px;
  height:100vh;
  width:calc(100% - 240px);
  max-height:100vh;
  overflow-x:hidden;
  background-color: #f5f8fb;
}

.dash-containter > div:nth-child(1) > img{
  margin-bottom:30px;
  width:100%;
}
.dropleft > button, .dropleft > button:active, .dropleft > button:focus{
  background:none !important;
  color:#fff !important;
}
.dropleft .dropdown-toggle::before {
  display:none !important;
}
.popup-menu-custom, .popup-menu-custom > div, .popup-menu-custom > div > button{
  width:100%;
  background-color:#262b40;
  padding:0;
  margin:0;
  outline: none;
  border:none;
}
.popup-menu-custom > div > button{
  margin-bottom:20px;
  padding:5px;
  font-size: 18px !important;
  border-radius:10px;
}

.popup-menu-custom > div > button:hover{
  background-color:#1f2336;
}

.popup-menu-custom > div > button > div{
  align-items: center;
  display:flex;
}
.popup-menu-custom .img-profile{
  width:50px;
  height:50px;
  margin-right:15px;
  border-radius:50%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.sidebar-custom{
  margin-top:25px;
  color:#f5f5f5;
}

.sidebar-custom > p{
  margin:10px 0;
  padding:15px 15px;
  border-radius:5px;
  font-size:18px;
}
.sidebar-custom > p:hover, .dropleft > button:hover:not(#dropdown-basic-button){
  background-color:#2e3650 !important;
}

.sidebar-custom > p > svg{
  margin-right:10px;
}
.sidebar-custom > p:hover{
  cursor:pointer;
}

.sidebar-active{
  background-color:#2e3650;
  border:1px solid rgba(255, 255, 255, 0.2);
}

td,tr,button{
  font-size: 12.5px !important;
}
p{
  font-size: 15px;
}
input{
  font-size: 15px !important;
}
.btn-default, .btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-warning{
  font-size: 15px !important;
}

.btn-warning{
  color:#ffc107 !important;
  border:1px solid #ffc107 !important;
  background:none !important;
}

.btn-warning:hover{
  color:#ffffff !important;
  background:#ffc107 !important;
}

.btn-secondary{
  color:#343a40 !important;
  border:1px solid #343a40 !important;
  background:none !important;
}
.btn-secondary:hover{
  color:#ffffff !important;
  background:#343a40 !important;
}

.btn-success{
  color:#28a745 !important;
  border:1px solid #28a745 !important;
  background:none !important;
}
.btn-success:hover{
  color:#ffffff !important;
  background:#28a745 !important;
}

.btn-primary{
  color:#007bff !important;
  border:1px solid #007bff !important;
  background:none !important;
}
.btn-primary:hover{
  color:#ffffff !important;
  background:#007bff !important;
}

.btn-danger{
  color:#fa5252 !important;
  border:1px solid #fa5252 !important;
  background:none !important;
}
.btn-danger:hover{
  color:#ffffff !important;
  background:#fa5252 !important;
}

.height-full{
  height:100vw;
}
.title-container{
  background-color: #f5f8fb;
  text-transform: capitalize;
}
.title-page{
  padding:20px 20px 0 38px;
  color:#3d3d3d;
}

.alert-position{
  position:fixed !important;
  top:60px !important;
  right:20px !important;
  z-index:200000 !important;
}

.spinner-position{
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.custom-overlay{
  width:100%;
  height:100vh;
  overflow: hidden;
  position:fixed;
  z-index:20000;
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-overlay-2{
  width:100vw;
  height:100vh;
  left:0;
  top:0;
  overflow: hidden;
  position:fixed;
  z-index:1;
  background-color: rgba(255, 255, 255, 0.5);
}


.table-responsive {
  /*display: table;*/
  overflow: auto;
}

.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header2-text > i{
  color:#fff !important;
  background:#262b40 !important;
  padding:8px !important;
  border-radius: 2px;
}
.header2-text-label{
  vertical-align: middle;
  font-size:24px;
}

.header2-text > i:hover{
  color:#000 !important;
}

.login-btn{
  font-size: 21px !important;
}

.scheduler-view{
  max-width:calc(100% - 368px) !important;
}
.ant-row-flex{
  max-width: 600px !important;
}
.scheduler> thead>tr>td>div>.ant-col:nth-child(2){
  display: none !important;
}
.ant-col{
  margin-bottom:60px;
}
.slot-cell > i{
  display: none !important;
}
.table-page-button{
  width:100%;
  text-align: right;
}
.resource-table > thead > tr >.header3-text{
  color:#ffffff !important;
}
.resource-view, .resource-table, .resource-view > div:nth-child(2){
  width:280px !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #262b40;
}

input:focus + .slider {
  box-shadow: 0 0 1px #262b40;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.resource-view>div:nth-child(1),.scheduler-bg-table>thead>tr>th{
  background-color:#2e3650;
  color:#fff;
}

thead{
  color:#262b40;
}

.setErrorField{
  border:1px solid red !important;
}

.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #85A3BF;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}


.rotate-device {
  position:fixed;
  background:#000;
  z-index:9999;
  top:0;
  margin:-122px auto;
  height:140%;
  width:100%;
  text-align:center;
  color:white;
  display:block;
  justify-content: center;
}

.phone {
  justify-content: center;
  text-align: center;
  margin:auto;
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  /* display: none; */
}

@keyframes rotate {
  0% {
		transform: rotate(0deg)
	}
	50% {
		transform: rotate(-90deg)
	}
	100% {
		transform: rotate(-90deg)
	}
}

@media only screen
and (max-device-width: 900px) 
and (orientation: landscape){
  .scheduler-view{
    width:100vw !important;
  }
  .rotate-device{
    display:none;
  }
}
@media only screen
and (max-device-width: 900px) 
{
  .popup-menu-custom{
    width:40px;
  }
  .popup-menu-custom > div > button{
    margin-bottom:0;
  }
  #statistics-tab {
    position: absolute;
    top: -100px;
  }
  .form-label{
    font-size:12px;
  }
  .nav-tabs > .nav-item {
    font-size:12px;
  }
  tr, td, td{
    white-space: nowrap !important;
  }
  .btn{
    font-size: 10px !important;
  }
}



.css-26l3qy-menu{
  position: fixed !important;
  top: auto !important;
  left: auto !important;
  width: 250px !important;
}



.rct-header-root{
  height:57px;
}
.rct-header-root > div:nth-child(1){
  text-align:center;
  font-weight: bolder;
  padding-top:10px;
  font-size:25px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding-left:0 !important;
  padding-right:0 !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row > div{
  padding:0 20px 0 20px !important;
  color:#3d3d3d;
  font-weight: bolder;
}

.highlight{
  background-color:#71b1f7 !important;
  color:#ffffff !important;
}

.rct-header-root > div:nth-child(1), .rct-calendar-header > div:nth-child(1){
  background-color:#262b40 !important;
  color:#ffffff !important;
}